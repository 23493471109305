body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::selection {
  color: #e57373;
  background: #1b1818;
}
.ql-syntax {
  background-color: #2d0d22;
  color: #eee;
  padding: 20px;
  border-radius: 5px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}

.text-capitalize,
tspan {
  text-transform: capitalize;
}

td {
  padding: 0.5rem !important;
}
article a {
  /* text-decoration: none; */
  color: #66a3e0;
}
.custom-tooltip {
  background: #fff;
  color: #1b1818;
  padding: 0 15px;
}
article h2 {
  margin-bottom: 0;
}

article img {
  max-width: 100%;
}

.article-list li {
  margin-bottom: 2%;
}
.article-topic {
  color: #e57373;
}

table,
th,
td {
  border: 1px solid #bebebe;
  border-collapse: collapse;
}
td {
  padding: 5px;
}

.img-fluid {
  width: 100%;
  height: auto;
  border-radius: 2%;
  cursor: pointer;
}

:focus-visible {
  outline: none;
}
.paris-style {
  font-family: "serif";
  font-size: 2rem;
  background: -webkit-linear-gradient(
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paris .wrapper {
  transform: skew(-10deg);
  transition: 0.25s;
}
.paris .wrapper:before {
  content: "";
  left: 0;
  top: 0;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  border-radius: 40px;
  opacity: 0;
  border: 2px solid #ece9e6;
  position: absolute;
  z-index: -1;
  transition: 0.5s 0.05s;
}
.paris .wrapper:hover:before {
  opacity: 1;
  transform: translateY(1.5rem) translateX(1.5rem);
}

.paris .wrapper:hover {
  transform: translateX(-1rem) translateY(-1rem) skew(-10deg);
}
.paris .wrapper:hover .card {
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.paris .wrapper:hover .card:after {
  background-blend-mode: normal;
}
.paris .wrapper:hover .card .fal {
  bottom: 4rem;
  background-color: rgba(255, 255, 255, 0.8);
}

.paris .card {
  cursor: pointer;
  border-radius: 40px;
  height: 15rem;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  background-color: #1b1818;

  /* prevent image focus */
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  /*IE10*/
  -ms-user-select: none;
  user-select: none;
  /*You just need this if you are only concerned with android and not desktop browsers.*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.paris .card:after {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.paris .card .card-title {
  position: absolute;
  transform: skew(10deg);
  bottom: 3rem;
  z-index: 99;
  text-transform: uppercase;
  font-family: monospace;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  /* transform: skew(-10deg); */
}
.paris .card .fal {
  position: absolute;
  bottom: -1rem;
  right: 3rem;
  border-radius: 100%;
  padding: 1rem;
  font-size: 0.5rem;
  color: #373f51;
  z-index: 1;
  transform: skew(10deg) translateY(50%);
  text-align: center;
  transition: 0.25s;
}

.paris .wraper-1 .card:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://github.com/bishaludash/bishaludash.github.io/blob/master/src/components/Paris/img/arc-de-triumph.jpg?raw=true");
  background-size: cover;
  /* background-color: #1b1818; */
  /* background-blend-mode: screen; */
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}

.paris .wrapper-2 .card:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://github.com/bishaludash/bishaludash.github.io/blob/master/src/components/Paris/img/saint-germain.jpg?raw=true");
  background-size: cover;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}

.paris .wrapper-3 .card:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://github.com/bishaludash/bishaludash.github.io/blob/master/src/components/Paris/img/shakespeareCo.jpg?raw=true");
  background-size: cover;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}

.paris .wrapper-4 .card:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("https://github.com/bishaludash/bishaludash.github.io/blob/master/src/components/Paris/img/restaurant.jpg?raw=true");
  background-size: cover;
  transform: skew(10deg) scale(1.2);
  transition: 0.25s;
}

/* Bootstrap helper styles */
/* bootstrap helper class */
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
